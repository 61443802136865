.divhover {
  border: 1px solid transparent;
}
.divhover:hover {
  border: 1px solid #78a2f0;
}
.top1 {
  margin-top: 5px;
}
.top2 {
  margin-top: 20px;
}
.btncss {
  margin-left: 85px;
  font-size: 14px;
}
.btncss:hover {
  background: #ed4014;
}
.btncss2 {
  background: #d9e7db;
  font-size: 14px;
}
.btncss2:hover {
  background: #30cfe4;
  border: 1px solid green;
  color: white;
}
.head {
  color: #606266;
  font-size: 13px;
  font-weight: 600;
}
.notop {
  background: #f1f0f0;
}
.bkcolor {
  background-color: #f8f9fa;
  font-size: 13px;
  font-weight: 600;
  line-height: 35px;
  border-left: 1px solid #ccc;
  border-right: 1px solid #ccc;
}
.title {
  line-height: 35px;
  border-right: 1px solid #ccc;
  border-left: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  display: flex;
  font-size: 13px;
  font-weight: 600;
}
.column {
  border-left: 1px solid #ccc;
  border-top: 1px solid #ccc;
}
.divcol {
  line-height: 30px;
  border-top: 1px solid #ccc;
  border-right: 1px solid #ccc;
  font-size: 14px;
  text-align: left;
  padding-left: 5px;
}
.divcol2 {
  line-height: 30px;
  border-top: 1px solid #ccc;
  font-size: 12px;
  text-align: left;
  padding-left: 5px;
}
.opercol {
  line-height: 30px;
  border-left: 1px solid #ccc;
  border-right: 1px solid #ccc;
  border-top: 1px solid #ccc;
  font-size: 14px;
  text-align: center;
}
.opercol2 {
  line-height: 30px;
  border-left: 1px solid #ccc;
  border-right: 1px solid #ccc;
  font-size: 14px;
  text-align: center;
}
