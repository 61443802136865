























































































































































































































































































































































































































.el-dialog__body{
    padding: 5px 10px;
}
.el-dialog__header{
    padding: 0px;
}
.el-dialog__footer{
    padding: 5px;
    border-top:1px solid #ccc;
} 
.el-dialog__headerbtn { margin-top:0px !important;  }
.el-dialog__headerbtn i { background:white;font-size: 20px; }
